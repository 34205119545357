import steelGif from './../assets/images/steel-gif.gif'
import greedIsGoodJpg from './../assets/images/greedIsGood.jpg'
import evercatchPng from './../assets/images/evercatch.png'
import cartPng from './../assets/images/cart.png'
import brochurePng from './../assets/images/brochure.png'
import lwcPng from './../assets/images/lwcpng.png'

const data = [
  {
    title: 'Awesome LWC',
    description: `A list of interesting Lightning Web Components resources and code examples`,
    image: lwcPng,
    repo: `https://github.com/Confirm4Crit/awesome-lwc`,
  },
]

export default data
