import steelGif from './../assets/images/steel-gif.gif'
import greedIsGoodJpg from './../assets/images/greedIsGood.jpg'
import evercatchPng from './../assets/images/evercatch.png'
import cartPng from './../assets/images/cart.png'
import brochurePng from './../assets/images/brochure.png'


const data = [
{
    title: 'OOD Shopping Cart',
    repo: 'https://github.com/Confirm4Crit/OODShoppingCartProject',
    description: `Group project involving creating a shopping cart app in Java, using SWING, JDBC.`,
    image: cartPng,
  },
  {
    title: 'Katrina (Senior Design Project)',
    description: `6 person group project during my senior year at FAU, involving specification documentation with UML modeling, understanding of scope and stakeholders, and extreme programming exposure.`,
    image: brochurePng,
  },
]

export default data